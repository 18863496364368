import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { Typography, Card } from "antd";
import styles from "./Entry.module.less";

const { Title } = Typography;

function extractPoints(str) {
  const match = str.match(/\((\d+) points\)/);
  return match ? parseInt(match[1], 10) : 1;
}

const Entry = () => {
  const { state } = useLocation();
  if (!state) return <Redirect to="/super-bowl-prop-bet-leaderboard" />;
  const { name, answers, points } = state.entry;
  const correctAnswers = state.answers;
  return (
    <div className={styles.entryPage}>
      <Title level={3}>{name}</Title>
      <Title level={3}>Score: {points}</Title>
      {Object.keys(answers).map((question) => {
        const style = { margin: "0.25rem 0" };
        let isCorrect = false;
        let isSettled = false;
        if (correctAnswers[question] && correctAnswers[question].answer) {
          isSettled = true;
          console.log(correctAnswers[question].answer);
          if (
            correctAnswers[question].answer === "PUSH" ||
            correctAnswers[question].answer === "VOID"
          ) {
            style.background = "rgb(205, 205, 205)";
          } else {
            if (correctAnswers[question].answer === answers[question]) {
              isCorrect = true;
              style.background = "#1da57a";
            } else {
              style.background = "#fe5454";
            }
          }
        }

        let score;
        if (isSettled) {
          score = !isCorrect
            ? 0
            : extractPoints(correctAnswers[question].answer);
        }

        let answerDisplay;
        if (!isSettled) {
          answerDisplay = (
            <div className={styles.answer}>{answers[question]}</div>
          );
        } else {
          if (isCorrect) {
            answerDisplay = (
              <div className={styles.answer}>
                <strong>{answers[question]}</strong>
              </div>
            );
          } else {
            // display strike through inccorect answer with correct answer next to it
            answerDisplay = (
              <div className={styles.answer}>
                <span style={{ textDecoration: "line-through" }}>
                  {answers[question]}
                </span>{" "}
                <strong>{correctAnswers[question].answer}</strong>
              </div>
            );
          }
        }

        return (
          <Card
            key={question}
            style={style}
            bodyStyle={{ padding: " 0.5rem 1rem" }}
          >
            <div className={styles.questionAndAnswer}>
              <div style={{ marginRight: "3rem" }}>
                <div className={styles.question}>{question}</div>
                {answerDisplay}
              </div>
              <div style={{ marginLeft: "auto", fontSize: 22 }}>{score}</div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default Entry;
